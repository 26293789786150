import makeStyles from '@mui/styles/makeStyles';

export default makeStyles(({v2, colors, fonts, font}) => ({
    label: {
        cursor: 'pointer',
        ...(v2
                ? fonts.bodyNormal
                : {
                      fontWeight: font.bold,
                      fontSize: '16px',
                  }),
    },
    checkboxList: {
        display: 'flex',
        flexFlow: 'column nowrap',
        gap: '4px',
        border: 'none',
        background: 'transparent',
        margin: 0,
        padding: 0,
    },
    checkboxListHeading: {
        display: 'block',
        margin: 0,
        padding: 0,
    },
    checkboxWithLabel: {
        display: 'flex',
        gap: '4px',
    },
    checkboxLabel: {
        cursor: 'pointer',
    },
    disabled: {},

    drawer: {
        left: 'auto',
        zIndex: 1300,
    },

    content: {
        width: v2 ? '424px' : '470px',
        color: v2 ? colors.textDefault : colors.darkGrey100,
        backgroundColor: v2 ? colors.uiGrayDark : colors.lightGrey60,
    },
    title: {
        paddingLeft: '20px',
        fontWeight: 700,
        fontSize: '20px',
        flex: '0 0 auto',
    },
    closeBtn: {
        position: 'absolute',
        top: 13,
        right: 13,
        width: 28,
        height: 28,
        padding: 1.5,
    },
    controls: {
        margin: '35px 15px 25px 20px',
        display: 'flex',
        flexFlow: 'column nowrap',
        gap: '25px',
    },

    selectMenuActions: {
        margin: '1em',
    },
}));
