/**
 * Results Service
 */
import merge from 'lodash/merge';
import omit from 'lodash/omit';
import pick from 'lodash/pick';
import upperfirst from 'lodash/upperFirst';

import {getPreviousPeriod} from 'hsi/utils/dates';
import http from 'hsi/classes/Http';
import {SavedSearchParamsType, getSearchParams, queryParamsToString} from 'hsi/utils/url';
import {SAVED_SEARCH_URLS, QUICK_SEARCH_URLS, API_SUPPORTS_LIMIT} from 'hsi/constants/urlTypes';

import {LinkedinChannelIdsType} from 'hsi/types/shared';
import {QueryContextType} from 'hsi/types/query';

import {
    AllFilteringState,
    APIFilterFormat,
    Aggregates,
    Breakdowns,
    DateRange,
} from 'hsi/types/filters';
import {filterStateToAPIFormat} from 'hsi/utils/filters';
import {TimezoneID} from 'hsi/utils/timezones';
import {RootReducer} from 'hsi/reducers/rootReducer';
import {isKeyOf, isStringIn} from 'hsi/utils/typeguards/shared';

// Constants
const loadDataRequestAbortControllersByType: {[key: string]: AbortController} = {};

const PEAKS_MAP = {
    mentionsHistory: 'queries',
    sentimentHistory: 'sentiment',
    emotionHistory: 'emotions',
} as const;

//if query type = quick, allowed values ar keyof QUICK_SEARCH_URLS
//this will hopefully change when we port to the new replacement for quicksearch
export type LoadDataTypes = keyof typeof QUICK_SEARCH_URLS | keyof typeof SAVED_SEARCH_URLS;

type LoadDataArgs = {
    queryContext: QueryContextType;
    type: LoadDataTypes;
    apiFilterParams: APIFilterFormat;
    dateRange: DateRange;
    additionalQueries: number[];
    orderBy?: string | null;
    linkedinChannelIds: LinkedinChannelIdsType;
    cardPersistState: RootReducer['cardPersistState'];
    limit?: number;
};

type DetectPeaksArgs = {
    queryContext: QueryContextType;
    type: keyof Breakdowns;
    filters: AllFilteringState;
    additionalQueries: number[];
    linkedinChannelIds: LinkedinChannelIdsType;
    cardPersistState: any;
};

/**
 *
 * @param type Used for type narrowing of the load data type
 * @returns
 */
function isSavedSearchType(type: LoadDataTypes): type is keyof typeof SAVED_SEARCH_URLS {
    return type in SAVED_SEARCH_URLS;
}

export default class ResultService {
    static abortAllLoadData() {
        Object.keys(loadDataRequestAbortControllersByType).forEach((key) => {
            !!loadDataRequestAbortControllersByType[key] &&
                loadDataRequestAbortControllersByType[key].abort();

            delete loadDataRequestAbortControllersByType[key];
        });
    }

    static abortType(type: string): void {
        !!loadDataRequestAbortControllersByType[type] &&
            loadDataRequestAbortControllersByType[type].abort();

        delete loadDataRequestAbortControllersByType[type];
    }

    static async loadData({
        queryContext,
        type,
        apiFilterParams,
        dateRange,
        additionalQueries,
        orderBy,
        linkedinChannelIds,
        cardPersistState,
        limit,
    }: LoadDataArgs) {
        //abort any existing call of this type
        !!loadDataRequestAbortControllersByType[type] &&
            loadDataRequestAbortControllersByType[type].abort();

        //create new abort controller
        loadDataRequestAbortControllersByType[type] = new AbortController();
        const {signal} = loadDataRequestAbortControllersByType[type];

        if (queryContext.searchType === 'quick') {
            const queryStringParamsObj = getSearchParams(
                apiFilterParams,
                dateRange,
                queryContext,
                undefined,
                undefined,
                true,
            );

            return http
                .get(
                    `/api/projects/${queryContext.projectId}${
                        QUICK_SEARCH_URLS[type as keyof typeof QUICK_SEARCH_URLS]
                    }${queryParamsToString(queryStringParamsObj)}`,
                    {
                        signal,
                    },
                )
                .then((res) => res.body);
        } else {
            //We can hopefully remove this when we replace the quicksearch implementation
            if (!isSavedSearchType(type)) {
                throw new Error('Unknown type value');
            }

            //If this is a saved search
            const queryStringParams: SavedSearchParamsType = getSearchParams(
                apiFilterParams,
                dateRange,
                queryContext,
                additionalQueries,
                linkedinChannelIds,
                true,
            ) as SavedSearchParamsType;

            if (limit && API_SUPPORTS_LIMIT.includes(type)) {
                queryStringParams.limit = limit;
            }

            // Nothing I love more than special rules for individual data types //
            if (['toptopicsBySearch', 'wordCloud'].includes(type)) {
                queryStringParams.metrics = 'sentiment,gender,trending,timeSeries';
                queryStringParams.extract = queryContext.additionalParams.extract?.join(',');
                queryStringParams.orderBy = queryContext.additionalParams.orderBy;
            }

            if (type === 'topauthors') {
                queryStringParams.orderBy = topAuthorsSortColumnToApi(orderBy);
            } else if (type === 'tophashtags') {
                if (orderBy && orderBy !== 'value') {
                    //defaults to volume, and 'value' = 'volume'
                    queryStringParams.orderBy = orderBy;
                }
            }

            if (isStringIn(type, ['benchmark', 'benchmarkBySearch'])) {
                const prevQueryStringParams = getSearchParams(
                    apiFilterParams,
                    {
                        ...getPreviousPeriod(
                            dateRange.startDate,
                            dateRange.endDate,
                            (dateRange?.timezone as TimezoneID) ||
                                (queryContext?.timezone as TimezoneID),
                        ),
                        relativeRange: null,
                        timezone: dateRange.timezone,
                    },
                    queryContext,
                    additionalQueries,
                    linkedinChannelIds,
                );

                const baseQuery =
                    type === 'benchmark'
                        ? SAVED_SEARCH_URLS[type].replace(
                              '{breakdown}',
                              cardPersistState[type]?.breakdown,
                          )
                        : SAVED_SEARCH_URLS[type];

                return Promise.all([
                    http
                        .get(
                            `/api/projects/${
                                queryContext.projectId
                            }${baseQuery}${queryParamsToString(queryStringParams)}`,
                            {signal},
                        )
                        .then((res) => ({
                            ['current' + upperfirst(res.body.dimension1)]: res.body,
                        })),
                    http
                        .get(
                            '/api/projects/' +
                                queryContext.projectId +
                                baseQuery +
                                prevQueryStringParams,
                            {signal},
                        )
                        .then((res) => ({
                            ['previous' + upperfirst(res.body.dimension1)]: res.body,
                        })),
                ]).then((results) => results.reduce((acc, curr) => ({...acc, ...curr}), {}));
            } else if (isStringIn(type, ['totalVolume', 'totalVolumeBySearch'])) {
                /*
                    Mentions volume over time and the showNoData general message depend on the mentions
                    volume. This is a workaround to be sure volume gets called everytime no matter what
                    */
                const allProxyMetrics = ['avgFollowers', 'retweetRate'] as const;
                const {projectId} = queryContext;
                queryStringParams.projectId = projectId;
                const metrics = ['volume', ...(queryContext.additionalParams.metrics ?? [])];
                const bwApiCalls = omit(pick(SAVED_SEARCH_URLS[type], metrics), allProxyMetrics);

                const queriesMap: string[] = Object.values(bwApiCalls).map((url: string) =>
                    url.replace(
                        '{breakdown}',
                        cardPersistState[type as keyof Breakdowns]?.breakdown as string,
                    ),
                );

                const prevQueryStringParams = {
                    ...getSearchParams(
                        apiFilterParams,
                        {
                            ...getPreviousPeriod(
                                dateRange.startDate,
                                dateRange.endDate,
                                (dateRange?.timezone as TimezoneID) ||
                                    (queryContext?.timezone as TimezoneID),
                            ),
                            relativeRange: null,
                            timezone: dateRange?.timezone,
                        },
                        queryContext,
                        additionalQueries,
                        linkedinChannelIds,
                        true,
                    ),
                    projectId,
                };

                //TODO better typing for the returned value here
                const proxyCalls = allProxyMetrics.reduce<Promise<any>[]>((output, metric) => {
                    if (metric in SAVED_SEARCH_URLS[type]) {
                        output.push(
                            http
                                .get(
                                    `${SAVED_SEARCH_URLS[type][metric]}${queryParamsToString(
                                        queryStringParams,
                                    )}`,
                                    {signal},
                                )
                                .then((results) => ({
                                    [metric]: {current: results.body},
                                })),
                        );

                        output.push(
                            http
                                .get(
                                    `${SAVED_SEARCH_URLS[type][metric]}${queryParamsToString(
                                        queryStringParams,
                                    )}`,
                                    {signal},
                                )
                                .then((results) => ({
                                    [metric]: {previous: results.body},
                                })),
                        );
                    }

                    return output;
                }, []);

                const currentCalls = queriesMap.map((urlChunk) =>
                    http
                        .get(
                            `/api/projects/${projectId}${urlChunk}${queryParamsToString(
                                queryStringParams,
                            )}`,
                            {signal},
                        )
                        .then((results) => ({
                            [results.body.aggregate]: {current: results.body},
                        })),
                );

                const previousCalls: Promise<any>[] = queriesMap.map((urlChunk) =>
                    http
                        .get(
                            `/api/projects/${projectId}${urlChunk}${queryParamsToString(
                                prevQueryStringParams,
                            )}`,
                            {
                                signal,
                            },
                        )
                        .then((results) => ({
                            [results.body.aggregate]: {previous: results.body},
                        })),
                );

                return Promise.all(currentCalls.concat(previousCalls).concat(proxyCalls)).then(
                    ([x, ...rest]) => merge(x, ...rest),
                );
            } else {
                let baseQuery = SAVED_SEARCH_URLS[type] as string;

                if (isKeyOf(type, cardPersistState)) {
                    baseQuery = baseQuery.replace(
                        '{breakdown}',
                        cardPersistState[type as keyof Breakdowns].breakdown,
                    );
                }

                if (isKeyOf(type, cardPersistState)) {
                    baseQuery = baseQuery.replace(
                        '{aggregate}',
                        cardPersistState[type as keyof Aggregates].aggregate,
                    );
                }

                return http
                    .get(
                        `/api/projects/${queryContext.projectId}${baseQuery}${queryParamsToString(
                            queryStringParams,
                        )}`,
                        {signal},
                    )
                    .then((res) => res.body);
            }
        }
    }

    static async detectPeaks({
        queryContext,
        type,
        filters,
        additionalQueries,
        linkedinChannelIds,
        cardPersistState,
    }: DetectPeaksArgs) {
        let dimension = PEAKS_MAP[type as keyof typeof PEAKS_MAP];
        let timeDimension = cardPersistState[type].breakdown;
        let queryStringParams = getSearchParams(
            filterStateToAPIFormat(filters.filters, filters.allFiltersConfig),
            filters.dateRange,
            queryContext,
            additionalQueries,
            linkedinChannelIds,
        );

        let peakParams = '&peaksLimit=20';

        return http
            .get(
                `/api/projects/${queryContext.project.id}/data/peaks/${timeDimension}/${dimension}` +
                    queryStringParams +
                    peakParams,
            )
            .then((res) => res.body);
    }
}

//Helpers
const sortKeyToApi = {
    twitterFollowers: 'followers',
    reachEstimate: 'reachEstimate',
} as const;

function topAuthorsSortColumnToApi(sortKey?: string | null) {
    return (sortKey !== null && sortKeyToApi[sortKey as keyof typeof sortKeyToApi]) || 'volume';
}
