import {useCallback, useState} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Slider, { SliderProps } from '@mui/material/Slider';

//Components
import Checkbox from 'hsi/components/Checkbox';
import Select from 'hsi/components/Select';

//Actions
import {reloadWordCloud} from 'hsi/actions/resultsActions';

// Hooks
import useEventTrack from 'hsi/hooks/useEventTrack';
import useFlags from 'hsi/hooks/useFlags';
import {useActiveQueryPageTypes} from 'hsi/hooks/usePageTypes';
import { useAppDispatch } from 'hsi/hooks/useRedux';
import useUniqueId from 'hsi/hooks/useUniqueId';

//Other
import {T} from 'hsi/i18n';
import useStyles from './styles';

//Constants
import { WORD_CLOUD_TYPES} from 'hsi/constants/config';
import { useCardTypeLimit } from 'hsi/utils/cards/getCardTypeLimit';


//Utils

//TODO type this properly
type ControlProps = {
    type: any, 
    selectedParams: any, 
    onParamChange: any, 
    options: Record<string, string[]>;
};

function Control ({type, selectedParams, onParamChange, options}: ControlProps) {
    const classes = useStyles();
    const id = useUniqueId(type);

    return <div>
        <label htmlFor={id} className={classes.label}>
            {T('configSideDrawer.wordCloud.' + type)}
        </label>
        <Select
            id={id}
            value={selectedParams[type]}
            onChange={(e) => onParamChange(e.target.value, type)}
        >
            {options[type].map((selectItem: string) => (
                <option key={type + selectItem} value={selectItem}>
                    {T('configSideDrawer.wordCloud.selectItems.' + type + '.' + selectItem)}
                </option>
            ))}
        </Select>
    </div>
}

export type WordCloudControlsProps = {
    selectedParams: any;//TODO properly type this
};


//The component
const WordCloudControls = ({selectedParams}: WordCloudControlsProps) => {
    const classes = useStyles();
    const dispatch = useAppDispatch();
    const {trackWithSearchData} = useEventTrack();
    const flags = useFlags();
    const pageTypes = useActiveQueryPageTypes();

    const [cloudSize, setCloudSize] = useState(selectedParams.size);

    const {limit: maxItems} = useCardTypeLimit('wordCloud');

    const options = {
        color: ['topicType', 'gender', 'sentiment', 'volume', 'trending', 'random', 'none'],
        orderBy: ['trending', 'volume'],
    };

    const onChangeSize: SliderProps['onChange'] = (e, value) => {
        setCloudSize(value);
    };

    const onChange = useCallback(
        (value: any, metric: any) => {
            const updatedValues = Object.assign({}, selectedParams, {[metric]: value});
            trackWithSearchData('cardCustomized', {
                metric,
                type: 'wordCloud',
                value,
            });
            dispatch(reloadWordCloud(updatedValues, metric, flags, pageTypes, maxItems));
        },
        [selectedParams, trackWithSearchData, dispatch, flags, pageTypes, maxItems],
    );

    const onParamChange = useCallback(
        (value: any, type: any) => {
            return value === selectedParams[type] ? false : onChange(value, type);
        },
        [onChange, selectedParams],
    );

    return (
        <>
            <fieldset className={classes.checkboxList}>
                <legend className={classNames(classes.checkboxListHeading, classes.label)}>
                    {T('configSideDrawer.wordCloud.show')}
                </legend>
                {WORD_CLOUD_TYPES.map(({id, label}) => {
                    const elemId = `wordCloudConfig-show-${id}`;

                    return <div className={classes.checkboxWithLabel} key={id}>
                        <Checkbox
                            id={elemId}
                            checked={selectedParams?.types?.includes(id)}
                            onChange={() => {
                                const newValue = WORD_CLOUD_TYPES.map(({id}) => id).filter((itemId) => itemId === id 
                                    ? !selectedParams?.types?.includes(id)// toggle  current value
                                    : selectedParams?.types?.includes(itemId)// keep current value
                                );

                                onParamChange(newValue, 'types');
                            }}
                        />
                            <label
                                className={classes.checkboxLabel}
                                htmlFor={elemId}
                            >
                                {label}
                            </label>
                    </div>
                })}
            </fieldset>
            <Control type="orderBy" selectedParams={selectedParams} onParamChange={onParamChange} options={options} />
            <Control type="color" selectedParams={selectedParams} onParamChange={onParamChange} options={options} />
            <div>
                <label className={classes.label} id="wordCloudConfigSizeLbl">{T('configSideDrawer.wordCloud.cloudSize')}</label>
                <Slider
                    aria-labelledby={'wordCloudConfigSizeLbl'}
                    max={maxItems}
                    min={1}
                    onChange={onChangeSize}
                    onChangeCommitted={(e, value) => onParamChange(value, 'size')}
                    value={cloudSize}
                    valueLabelDisplay="auto"
                />
            </div>
        </>
    );
};

WordCloudControls.propTypes = {
    selectedParams: PropTypes.object.isRequired,
};

export default WordCloudControls;
