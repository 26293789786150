import {memo, useCallback, useRef, useMemo, useState, MutableRefObject} from 'react';
import cn from 'classnames';
import capitalize from 'lodash/capitalize';
import isEmpty from 'lodash/isEmpty';

// Components
import Annotation from './Annotation';
import Button from 'hsi/components/Button';
import ChipMultipleSelect from 'hsi/components/ChipMultipleSelect';
import {Dialog, DialogContent, DialogActions} from 'hsi/components/Dialog';
import ExportHeader from 'hsi/components/ExportHeader';
import PulseLoader from 'hsi/components/PulseLoader';

// Hooks
import useConfig from 'hsi/hooks/useConfig';
import useEventTrack from 'hsi/hooks/useEventTrack';
import useFlags from 'hsi/hooks/useFlags';

import useGetCards from 'hsi/hooks/useGetCards';
import useOtherQueryNames from 'hsi/hooks/useOtherQueryNames';
import {useAppDispatch, useAppSelector} from 'hsi/hooks/useRedux';
import useStyles from './styles';
import useTimezone from 'hsi/hooks/useTimezone';
import {VisibleContainer} from 'hsi/hooks/useOnVisible';

// Actions
import {exportToPDF, saveExportConfig} from 'hsi/actions/exportActions';

// Contexts
import {IsCardInteractivityDisabled} from 'hsi/contexts/IsCardInteractivityDisabled';

// Utils
import {getRelativePos} from 'hsi/utils/scroll';
import {formatCardTitleAsString} from 'hsi/components/Card/CardTitle';

// Other
import {T} from 'hsi/i18n';

//Types
import {AnnotationKey, ChartKey} from 'hsi/types/charts';
import {CardComponentConfig} from 'hsi/types/cards';
import {Breakdowns, Aggregates} from 'hsi/types/filters';

type ExportWithNotesModalProps = {
    handleClose: () => void;
    isMultipleSearch?: boolean;
    open: boolean;
    savedSearchId: number;
    savedSearchName: string;
};

// Consts
const visibleContainerOptions = {margin: 250};
const MAX_LENGTH = 1000;

//The component
const ExportWithNotesModal = memo(
    ({
        handleClose,
        isMultipleSearch,
        open,
        savedSearchId,
        savedSearchName,
    }: ExportWithNotesModalProps) => {
        const classes = useStyles();
        const {searchResults: config, appSource, exportType} = useConfig();
        const dispatch = useAppDispatch();
        const {track, trackWithSearchData} = useEventTrack();
        const flags = useFlags();
        const _cards = useGetCards(config);
        const names = useOtherQueryNames();
        const timezone = useTimezone();

        // Selectors
        const {cardsToExport, annotations: initialAnnotions} = useAppSelector(
            (state) => state.pdfExport,
        );
        const dateRange = useAppSelector((state) => state.filters.dateRange);
        // TODO: Once filters are typed this should be updated
        const cardPersistState = useAppSelector((state) => state.cardPersistState);
        const charts = useAppSelector((state) => state.chart);

        // State
        const [hasError, setHasError] = useState(false);
        const [hasAnyAnnotations, setHasAnyAnnotations] = useState(() => {
            const initial = initialAnnotions?.[savedSearchId];

            if (!initial) {
                return false;
            }

            return Object.values(initial).some((annotation) => !!annotation);
        });

        // Refs
        const annotationsRef = useRef<Partial<Record<AnnotationKey, string | undefined>> | null>(
            null,
        );
        const elementsRef = useRef<Partial<Record<ChartKey, HTMLDivElement | null>> | null>(null);
        const modalAreaRef = useRef<HTMLDivElement | null>(null);

        if (!annotationsRef?.current) {
            annotationsRef.current = initialAnnotions ? initialAnnotions?.[savedSearchId] : {};
        }

        // Memos
        const subtitle = useMemo(
            () =>
                isMultipleSearch && names
                    ? T('exportToPDF.header.multipleSearch', {other: names.join(', ')})
                    : null,
            [isMultipleSearch, names],
        );

        // Some formatting for the dropdown checkboxes
        const cards = useMemo(
            () =>
                _cards.map((card) => ({
                    ...card,
                    label: formatCardTitleAsString(
                        card.title,
                        cardPersistState[card.name as keyof Breakdowns]?.breakdown,
                    ),
                })),
            [_cards, cardPersistState],
        );

        const defaultSelectedCards = useMemo(
            () =>
                (cardsToExport?.[savedSearchId] || []).reduce<Partial<Record<ChartKey, boolean>>>(
                    (output, cardName) => {
                        output[cardName] = true;

                        return output;
                    },
                    {} as Partial<Record<ChartKey, boolean>>,
                ),
            [cardsToExport, savedSearchId],
        );

        const [selectedCards, setSelectedCards] =
            useState<Partial<Record<ChartKey, boolean>>>(defaultSelectedCards);

        const cardPickOptions = useMemo(
            () => cards.map(({name, title}) => ({
                name, 
                label: formatCardTitleAsString(
                    title, 
                    cardPersistState[name as keyof Breakdowns]?.breakdown ?? undefined, 
                    cardPersistState[name as keyof Aggregates]?.aggregate ?? undefined
                )})),
            [cardPersistState, cards],
        );

        const selectedCardPickOptions = useMemo(
            () => cardPickOptions.filter((cardPickOption) => !!selectedCards[cardPickOption.name]),
            [cardPickOptions, selectedCards],
        );

        const selectedCardsLoaded = cards.every((card) => {
            if (isEmpty(selectedCards)) return charts[card.name].loaded === true;
            else return !selectedCards[card.name] || charts[card.name].loaded === true;
        });

        //Callbacks
        //-annotation callbacks
        const checkAnnotationErrors = useCallback(() => {
            const annotations = annotationsRef.current;

            if (isEmpty(annotations) || !annotations) {
                setHasError(false);
                setHasAnyAnnotations(false);
            } else {
                const annotationsArr = Object.values(annotations);

                setHasError(annotationsArr.some((annotation) => annotation?.length > MAX_LENGTH));
                setHasAnyAnnotations(annotationsArr.some((annotation) => annotation?.length > 0));
            }
        }, []);

        const setAnnotation = useCallback(
            (name: AnnotationKey, value: string | undefined) => {
                annotationsRef.current = {...annotationsRef.current, [name]: value};

                checkAnnotationErrors();
            },
            // eslint-disable-next-line react-hooks/exhaustive-deps
            [],
        );

        const clearAnnotations = useCallback(
            (name?: AnnotationKey) => {
                if (name) {
                    setAnnotation(name, '');
                } else {
                    annotationsRef.current = {};

                    checkAnnotationErrors();
                }
            },
            // eslint-disable-next-line react-hooks/exhaustive-deps
            [],
        );

        const onClose = useCallback(() => {
            handleClose?.();
        }, [handleClose]);

        const onExportAsPDF = async () => {
            const annotations = annotationsRef.current;
            trackWithSearchData('dashboardPdfClicked', {annotations,selectedCards: (Object.keys(selectedCards) as ChartKey[]).filter(
                (cardName) => selectedCards[cardName],
            ), });

            await dispatch(
                saveExportConfig({
                    annotations: {...annotations, [savedSearchId]: annotations},
                    cardsToExport: {
                        ...cardsToExport,
                        [savedSearchId]: (Object.keys(selectedCards) as ChartKey[]).filter(
                            (cardName) => selectedCards[cardName],
                        ),
                    },
                }),
            );
            const onSuccess = () => {
                trackWithSearchData('dashboardPdfExported', {});
            };

            // TODO: This action needs to be typed for onSuccess to be valid
            dispatch(exportToPDF(flags!, appSource, selectedCardsLoaded, onSuccess as any));
            onClose();
        };

        const onCancel = () => {
            track('reportCancelClicked');
            onClose();
        }

        const saveCardsToExport = useCallback(
            (selectedOptions: typeof cardPickOptions) => {
                setSelectedCards(
                    selectedOptions.reduce<typeof selectedCards>((output, {name}) => {
                        output[name] = true;

                        return output;
                    }, {} as typeof selectedCards),
                );
            },
            [setSelectedCards],
        );

        const scrollHandler = useCallback(
            (event: React.UIEvent<HTMLElement>, cardName: ChartKey) => {
                event.stopPropagation();
                if (modalAreaRef?.current && elementsRef?.current?.[cardName]) {
                    modalAreaRef.current.scrollTop = getRelativePos(
                        elementsRef.current[cardName]!,
                        modalAreaRef.current,
                    ).top;
                }
            },
            [],
        );

        const deleteHandler = useCallback(
            (deletedOption: {name: string}) =>
                setSelectedCards((currentSelectedCards: typeof selectedCards) => ({
                    ...currentSelectedCards,
                    [deletedOption.name]: false,
                })),
            [setSelectedCards],
        );

        //Render
        //-this used to be in a useMemo, but for some reason that prevented annotations from updating when using the clear all button
        const exportAll = Object.values(selectedCards).every((val) => !val);

        const selectCardsConfig = useMemo(
            () => (exportAll ? cards : cards.filter(({name}) => !!selectedCards[name])),
            [cards, exportAll, selectedCards],
        );

        const cardSections = useMemo(
            () =>
                selectCardsConfig.map((card) => {
                    return (
                        <CardAnnotation
                            key={card.name}
                            card={card}
                            setAnnotation={setAnnotation}
                            annotationsRef={annotationsRef}
                            elementsRef={elementsRef}
                            breakdown={cardPersistState[card.name as keyof Breakdowns]?.breakdown}
                            aggregate={cardPersistState[card.name as keyof Aggregates]?.aggregate || undefined}
                            classes={classes}
                        />
                    );
                }),
            [cardPersistState, classes, selectCardsConfig, setAnnotation],
        );

        if (!open) return null;

        return (
            <Dialog
                className={classes.createPDFExport}
                onClose={onClose}
                open={open}
                title={T('exportToPDF.annotations.modalTitle')}
            >
                <VisibleContainer options={visibleContainerOptions}>
                    <DialogContent ref={modalAreaRef}>
                        <div className={classes.leftAndRight}>
                            <ExportHeader
                                title={savedSearchName}
                                subtitle={subtitle}
                                startDate={dateRange.startDate}
                                endDate={dateRange.endDate}
                                timezone={timezone}
                                tagline={T(`exportToPDF.header.${exportType}`)}
                            />

                            {hasAnyAnnotations && (
                                <button
                                    onClick={() => clearAnnotations()}
                                    className={classes.clear}
                                >
                                    {T('exportToPDF.annotations.clearAll')}
                                </button>
                            )}
                        </div>

                        <Annotation
                            clearLabel={
                                <>
                                    <span aria-hidden="true">
                                        {T('exportToPDF.annotations.clear')}
                                    </span>
                                    <span className="offscreen">
                                        {T('exportToPDF.annotations.clearSummaryAccessible')}
                                    </span>
                                </>
                            }
                            label={
                                <>
                                    <span aria-hidden="true">
                                        {capitalize(T('exportToPDF.annotations.summary'))}
                                    </span>
                                    <span className="offscreen">
                                        {T('exportToPDF.annotations.summaryLabel')}
                                    </span>
                                </>
                            }
                            maxLength={MAX_LENGTH}
                            name="summary"
                            placeholder={T('exportToPDF.annotations.summaryPlaceholder')}
                            setValue={setAnnotation}
                            valuesRef={annotationsRef}
                        />

                        <div className={cn(classes.fieldLabel, classes.sectionMargin)}>
                            {T('exportToPDF.annotations.cardsSelector')}
                        </div>

                        <ChipMultipleSelect
                            applyHandler={saveCardsToExport}
                            btnLabel="exportToPDF.cardSelectLbl"
                            chipsLbl="exportToPDF.selectedCards"
                            deleteHandler={deleteHandler}
                            options={cardPickOptions}
                            placeholder={T('exportToPDF.annotations.byDefault')}
                            selectedOptions={selectedCardPickOptions}
                            scrollHandler={scrollHandler}
                        />

                        {cardSections}
                    </DialogContent>
                </VisibleContainer>
                <DialogActions>
                    <Button onClick={onCancel} priority="secondary">
                        {T('exportToPDF.goBack')}
                    </Button>
                    <Button
                        priority="cta"
                        onClick={onExportAsPDF}
                        disabled={!selectedCardsLoaded || !!hasError}
                    >
                        {selectedCardsLoaded ? T('exportToPDF.save') : <PulseLoader />}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    },
);

export default ExportWithNotesModal;

type CardAnnotationProps = {
    card: CardComponentConfig;
    setAnnotation: (name: AnnotationKey, value: string | undefined) => void;
    annotationsRef: MutableRefObject<Partial<Record<AnnotationKey, string | undefined>> | null>;
    elementsRef: MutableRefObject<Partial<Record<ChartKey, HTMLDivElement | null>> | null>;
    breakdown: string | undefined; //Partial<Record<ChartKey, string>>;
    aggregate: string | undefined;
    classes: ReturnType<typeof useStyles>;
};

const CardAnnotation = memo(function CardAnnotation({
    card,
    setAnnotation,
    annotationsRef,
    elementsRef,
    breakdown,
    aggregate,
    classes,
}: CardAnnotationProps) {
    const cardTitle = formatCardTitleAsString(card.title, breakdown, aggregate).toLowerCase();

    return (
        <section key={card.name} data-testid={`${card.name}-section`}>
            <div
                ref={(element) => {
                    if (elementsRef?.current?.[card.name]) {
                        elementsRef.current[card.name] = element;
                    }
                }}
                className={cn(classes.cardWrapper, 'printMedia')}
            >
                <IsCardInteractivityDisabled.Provider value={true}>
                    <card.component height={card.height} title={card.title} renderWhenVisible />
                </IsCardInteractivityDisabled.Provider>
            </div>
            <Annotation
                clearLabel={
                    <>
                        <span aria-hidden="true">{T('exportToPDF.annotations.clear')}</span>
                        <span className="offscreen">
                            {T('exportToPDF.annotations.clearAccessible', {cardTitle})}
                        </span>
                    </>
                }
                label={
                    <>
                        <span aria-hidden="true">
                            {capitalize(T('exportToPDF.annotations.notes'))}
                        </span>
                        <span className="offscreen">
                            {T('exportToPDF.annotations.notesLabel', {cardTitle})}
                        </span>
                    </>
                }
                maxLength={MAX_LENGTH}
                name={card.name}
                setValue={setAnnotation}
                valuesRef={annotationsRef}
            />
        </section>
    );
});
