//TODO proper typing

import compact from 'lodash/compact';
import filter from 'lodash/filter';

//Components
import Select from 'hsi/components/Select';

//Hooks
import useEventTrack from 'hsi/hooks/useEventTrack';
import useFlags from 'hsi/hooks/useFlags';
import {useActiveQueryPageTypes} from 'hsi/hooks/usePageTypes';
import { useAppDispatch } from 'hsi/hooks/useRedux';

//Actions
import {reloadTotalVolume} from 'hsi/actions/resultsActions';

//Consts
import {TOTAL_VOLUME_TYPES} from 'hsi/constants/config';

//Other
import {T} from 'hsi/i18n';
import useStyles from './styles';

//Types
export type MetricName = `metric${number}`;

export type TotalVolumeStateParams = {
    [key: MetricName]: {id: string, label: string}
};

export type TotalVolumeControlsProps = {
    selectedParams: TotalVolumeStateParams;
};


//The component
export default function TotalVolumeControls({selectedParams}: TotalVolumeControlsProps) {
    const classes = useStyles();
    const dispatch = useAppDispatch();
    const {trackWithSearchData} = useEventTrack();
    const flags = useFlags();
    const pageTypes = useActiveQueryPageTypes();

    const onParamChange = (value: string, type: MetricName) => {
        if (value !== selectedParams[type]?.id) {
            const updatedValues: TotalVolumeStateParams = {
                ...selectedParams,
                [type]: filter(TOTAL_VOLUME_TYPES, {id: value})[0]
            }

            trackWithSearchData('cardCustomized', {
                metric: type,
                type: 'totalVolume',
                value: compact(Object.values(updatedValues)).map((m) => m.label),
            });
            dispatch(reloadTotalVolume(updatedValues, flags, pageTypes));
        }
    };

    return ([...Array(TOTAL_VOLUME_TYPES.length)].map((_, i) => {
            const n = i+1;
            const type: MetricName = `metric${n}`;
            const id = `select-${type}-${n}`;

            return (
                <div key={id}>
                    <label className={classes.label} htmlFor={id}>
                        {T('configSideDrawer.totalVolume.select.label', {i: n})}
                    </label>
                    <Select
                        id={id}
                        onChange={(e) => onParamChange(e.target.value, type)}
                        value={selectedParams?.[type]?.id || ''}
                    >
                        <option value="">{T('configSideDrawer.totalVolume.select.none')}</option>
                        {TOTAL_VOLUME_TYPES.map((selectItem) => (
                            <option key={type + selectItem.id} value={selectItem.id}>
                                {selectItem.label}
                            </option>
                        ))}
                    </Select>
                </div>
            );
        })) as any;//Needed to fix error where TS incorrectly states that 'Element[]' is not a valid return type
};
