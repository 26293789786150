// This component is built specifically for the filters drawer, and is only really applicable there
import React, {useRef, useCallback, ReactNode, ComponentProps} from 'react';
import MuiDrawer, {DrawerProps as MuiDrawerProps} from '@mui/material/Drawer';
import classNames from 'classnames';

//Components
import VerticalScroll from 'hsi/components/layout/VerticalScroll';

//Utils
import {scrollToChildElement} from 'hsi/utils/scroll';

//Other
import useStyles from './styles';

//Constants
const SCROLL_OFFSET = 300;

type DrawerProps<T extends React.ElementType> = 
    Pick<MuiDrawerProps, 'anchor' | 'open' | 'variant'> & 
    {
        children: (autoScrollDrawer: (elem: Element) => void) => ReactNode | ReactNode;
        widthType?: string; //currently just 'filters' - can probably get rid of this?
        forceSticky?: boolean;
        as: T;
    } & 
    Omit<ComponentProps<T>, 'anchor' | 'open' | 'variant' | 'children' | 'widthType' | 'forceSticky' | 'as'>;

//The component
export default function Drawer<T extends React.ElementType = 'div'>({
    as,
    children,
    variant = 'persistent',
    anchor,
    open,
    widthType,
    forceSticky = false,
    className,
    ...rest
}: DrawerProps<T>) {
    const Component: T = as || 'div';

    const classes = useStyles();

    const drawerRef = useRef<HTMLDivElement>(null);

    const autoScrollDrawer = useCallback(
        (childRef: Element) =>
            drawerRef.current && scrollToChildElement(drawerRef.current, childRef, SCROLL_OFFSET),
        [],
    );

    return (
        <Component className={classNames(classes.root, className)} {...(rest as any)}>
            <MuiDrawer //Why are we even using this component when we are forcing it to behave in this way?
                variant={variant}
                anchor={anchor}
                open={true}
                className={classNames(
                    classes.drawer,
                    classes[anchor + 'Anchored'],
                    widthType,
                    forceSticky && 'forceSticky',
                    {
                        [classes.drawerOpen]: open,
                        [classes.drawerClose]: !open,
                    },
                    {
                        collapsed: !open,
                    },
                )}
                classes={{
                    paper: classNames({
                        [classes.drawerOpen]: open,
                        [classes.drawerClose]: !open,
                    }),
                }}
            >
                <VerticalScroll ref={drawerRef}>
                    {children instanceof Function
                        ? children(autoScrollDrawer)
                        : React.cloneElement(children, {autoScrollDrawer})}
                </VerticalScroll>
            </MuiDrawer>
        </Component>
    );
}
